@import "_default.scss_include-mixins";

.mobile-menu {
	display:block;
	position:fixed;
	left:0;
	top:-9999px;
	width:100%;
	height:100%;
	background-color:#fff;
	z-index:9999;
	background-position:center;
	background-repeat:no-repeat;
	background-size:cover;
	text-align:center;

	.logo {
		display:inline-block;
		top:30px;
		left:30px;
		width:150px;
		height:60px;

		.st0{
			fill:#77C9B3;
		}

		.name {
			float:left;
			margin-top:8px;
			margin-left:20px;
			font-family:'Poppins';
			font-size:25px;
			line-height:25px;
			text-transform:uppercase;
			font-weight:900;
		}
	}

	.close {
		position:absolute;
		top:30px;
		right:30px;
		width:45px;
		height:45px;
		cursor:pointer;
		z-index:10;

		.lines {
			float:left;
			width:100%;
			height:100%;
			height:100%;

			.line {
				position:absolute;
				margin-top:0;
				margin-left:0;
				width:100%;
				height:5px;
				background-color:#fff;

				&.line-1 {
					margin-top:19px;
					transform:rotate(-45deg);
				}
				&.line-2 {
					margin-top:19px;
					transform:rotate(-135deg);
				}
			}

		}

	}
	
	.scroller {
		position:relative;
		width:100vw;
		height:100vh;

		.scroller-inner {
			position:absolute;
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);
			text-align:center;
			width:250px;

			.button {
				background-color:#e1e1e1;
			}

			/*
			Left
			*/
			nav {
				float:left;
				width:100%;
				margin-top:20px;

				a {
					float:left;
					width:100%;
					text-decoration:none;
					color:#000;
					font-family:'Roboto Slab';
					font-size:30px;
					line-height:30px;
					margin-bottom:30px;
					font-weight:bold;

					&:hover {
						text-decoration:underline;
					}

				}

			}

		}

	}


}