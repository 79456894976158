@import "_default.scss_include-mixins";

footer {
	float:left;
	width:100%;
	background-image:url('../img/static/footer-image.jpg');
	background-color:#000;
	background-size:cover;
	background-position:center;

	.column {
		float:left;
		width:20%;
		margin-left:40px;

		@include md {
			clear:both;
			margin-left:0;
			width:100%;
			margin-bottom:30px;

			&:last-child {
				margin-bottom:0;
			}
		}

		a {
			color:#fff;
			text-decoration:none;
		}

		&:first-child {
			margin-left:0;
		}

		&:last-child {
			width:50%;

			@include md {
				width:100%;
			}
		}
	}
}