@import "_default.scss_include-mixins";

.color-white {
	color:#fff !important;
}

.color-black {
	color:#000 !important;
}

.color-green {
	color:#77C9B3 !important;
}

/*
Backgrounds
*/
.bckgrnd-color-white {
	background-color:#fff;
}

.bckgrnd-color-black {
	background-color:#000;
}