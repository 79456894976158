@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	h2 {
		font-family:'Roboto Slab';
		font-size:40px;
		line-height:40px;
		padding:0;
		margin:0;
		margin-bottom:20px;
		font-weight:900;
		color:#000;

		@include md {
			font-size:20px;
			line-height:20px;
		}
	}

	h3 {
		font-family:'Roboto Slab';
		font-size:70px;
		line-height:70px;
		padding:0;
		margin:0;
		margin-bottom:20px;
		font-weight:900;
		color:#000;

		@include md {
			font-size:30px;
			line-height:30px;
		}

	}

	.p, 
	p {
		float:left;
		width:100%;
		font-size:20px;
		line-height:33px;
		margin:0;

		@include md {
			font-size:14px;
			line-height:22px;
		}

	}

	ul {
		float:left;
		width:100%;
		margin:0;
		padding:0;

		&.greendot {	

			margin-bottom:20px;
			margin-left:20px;

			li {
				font-size:20px;
				line-height:33px;
				list-style:none;

				@include md {
					font-size:14px;
					line-height:22px;
				}

				&::before {
					content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
					color: #77C9B3; /* Change the color */
					font-weight: bold; /* If you want it to be bold */
					display: inline-block; /* Needed to add space between the bullet and the text */
					width: 1em; /* Also needed for space (tweak if needed) */
					margin-left: -1em; /* Also needed for space (tweak if needed) */
				}
			}
		}

		&.checkdot {

			margin-bottom:20px;

			li {
				font-size:20px;
				line-height:33px;
				list-style:none;

				@include md {
					float:left;
					width:100%;
					font-size:14px;
					line-height:22px;
				}

				span.icon {
					float:left;
					width:5%;

					svg {
						width:30px;
						height:30px;
						fill:#77C9B3;

						@include md {
							width:20px;
							height:20px;
						}
					}
				}

				span.text {
					float:left;
					width:90%;
					margin-left:20px;
					text-align:left;
					margin-bottom:10px;

					@include md {
						width:80%;
					}

					svg {
						width:100%;
						height:100%;
						fill:#77C9B3;
					}
				}
			}
		}

	}

}