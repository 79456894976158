@import "_default.scss_include-mixins";

.popup--content {
	float:left;
	width::1100px;

	@include sm {
		width:280px;
	}

	.content {
		float:left;
		width:100%;
		color:#000;
		background-color:#fff;
		padding:40px;
		border-radius:0;
		text-align:center;

		@include md {
			padding:20px;
		}

	}

}
	