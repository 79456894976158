@import "_default.scss_include-mixins";

.button-1 {

	display:inline-block;

	padding-top:15px;
	padding-bottom:15px;
	padding-left:25px;
	padding-right:25px;	
	cursor:pointer;

	@include md {
		float:left;
		width:100%;
		padding-left:15px;
		padding-right:15px;
		padding-top:10px;
		padding-bottom:10px;
		margin-left:0;
		margin-top:10px;
		margin-bottom:10px;

		.inside {
			.label {
				width:100%;
				text-align:center !important;
			}
		}
		
	}


	&.bckgrnd-white {
		background-color:#fff;

		.inside {
			.label {
				color:#000;
			}
		}
	}
	&.bckgrnd-black {
		background-color:#1C1C1E;

		.inside {
			.label {
				color:#fff;
			}
		}
	}
	&.bckgrnd-green {
		background-color:#77C9B3;

		.inside {
			.label {
				color:#fff;
			}
		}
	}

	.inside {

		float:left;
		width:100%;

		.label {
			float:left;
			text-align:center;
			font-size:18px;
			line-height:18px;
			font-weight:bold;

			@include md {
				font-size:13px;
			}
		}

		.icon {
			position:absolute;
			float:left;
			margin-left:20px;
			margin-top:2px;
			transition:all 0.3s;
			
			svg {
				float:left;
				width:24px;
				height:20px;
			}
		}

	}

	&:hover {

		.label {
			text-decoration:underline;
		}

		.icon {
			// margin-left:30px;
		}
		
	}

};