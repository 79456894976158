@import "_default.scss_include-mixins";

body {
	font-family: 'Open Sans', sans-serif;
	background-color:#77C9B3;
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}

/*
Container
*/

.container.ex {
	@include md {
		padding-right:0;
		padding-left:0;
	}
}
/*
Hero ********************************
Hero ********************************
Hero ********************************
Hero ********************************
*/
.hero {
	position:relative;
	float:left;
	width:100%;
	height:70vh;
	min-height:600px;
	background-color:#000;

	.button {
		@include md {
			float:none;
			margin-top:20px;
			width:auto;
		}
	}

	@include md {
		height:60vh;
		min-height:200px;
	}

	.inside-image {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-size:cover;
		background-repeat:no-repeat;
		background-position:center;

		.inside-image-overlay {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background-color:rgba(0,0,0,0.6);
		}
	}

	.inside-overlay {
		position:absolute;
		top:50%;
		left:50%;
		width:100%;
		margin-top:20px;
		transform:translate(-50%,-50%);
		text-align:center;
		z-index:5;

		@include md {
			//margin-top:5px;
		}

		h1 {
			font-size:80px;
			line-height:80px;
			padding:0;
			margin:0;
			text-transform: uppercase;
			text-shadow:0 0 20px rgba(0,0,0,0.7);
			overflow-wrap: break-word;

			@include md {
				font-size:20px;
				line-height:20px;
			}

		}

		h2 {
			font-size:60px;
			line-height:60px;
			padding:0;
			margin:0;
			margin-top:30px;
			text-transform: uppercase;
			font-weight:normal;
			text-shadow:0 0 20px rgba(0,0,0,0.7);

			@include md {
				margin-top:10px;
				font-size:15px;
				line-height:15px;
			}

		}
	}
}

.margin-top-120 {
	margin-top:120px;

	@include md {
		margin-top:40px;
	}
}

.padding-top-120 {
	padding-top:120px;

	@include md {
		padding-top:40px;
	}
}

.padding-bottom-120 {
	padding-bottom:120px;

	@include md {
		padding-bottom:40px;
	}

}

/*
Rest ********************************
Rest ********************************
Rest ********************************
Rest ********************************
*/
.rest {
	float:left;
	width:100%;

	/*
	Whitebox
	*/
	.whitebox {
		float:left;
		width:100%;
		background-color:#fff;
		padding:40px;
		padding-top:60px;
		padding-bottom:60px;

		@include md {
			padding:20px;

			.col-md-5 {
				.textblock-1 {
					margin-top:20px;
				}
			}
		}

		&.minus {
			margin-top:-80px;

			@include md {
				margin-top:-40px;
			}

		}
	}

	/*
	Quote
	*/
	.quote {
		display:inline-block;
		width:100%;

		.image {
			display:inline-block;
			width:200px;
			height:200px;
			background-color:#ccc;
			background-size:cover;
			background-position:center;
			border-radius:50%;
		}

		.name {
			float:left;
			margin-top:20px;
			width:100%;
			font-family:'Roboto Slab';
			font-size:30px;
			font-weight:bold;
			color:#000;

			@include md {
				font-size:20px;
			}

		}

		.text {
			float:left;
			margin-top:20px;
			width:100%;
			font-style: italic;
			color:#000;

			@include md {
				font-size:15px;
			}

		}

		.subtext {
			float:left;
			margin-top:10px;
			width:100%;
			color:#000;

			@include md {
				font-size:15px;
			}
		}

	}

	/*
	Block: Vacature
	*/
	.block-vacatures {
		float:left;
		width:100%;
		background-color:#fff;

		.vacature-item {
			float:left;
			width:100%;
			padding:40px;
			background-color:#E9E9E9;
			transition:0.2s all;
			margin-bottom:20px;

			@include md {
				padding:20px;
			}

			&:last-child {
				margin-bottom:0;
			}

			&:hover {
				background-color:#e1e1e1;
			}

			.job {
				float:left;
				width:50%;
				font-family:'Roboto Slab';
				font-size:25px;
				line-height:25px;
				font-weight:bold;
				color:#000;

				@include md {
					width:100%;
					font-size:20px;
					line-height:20px;
				}

			}

			.city {
				float:left;
				margin-top:5px;
				width:20%;
				font-size:20px;
				line-height:20px;
				color:#000;

				@include md {
					margin-top:10px;
					width:100%;
					font-size:20px;
					line-height:20px;
				}

			}
			.time {
				float:left;
				margin-top:5px;
				width:20%;
				font-size:20px;
				line-height:20px;
				color:#000;

				@include md {
					margin-top:10px;
					width:100%;
					font-size:20px;
					line-height:20px;
				}
			}
			.arrow {
				float:left;
				width:10%;

				svg {
					fill:#fff;
				}

				@include md {
					margin-top:10px;
					width:100%;

					svg {
						float:right;
					}
				}
			}
		}
	}

	/*
	Block: Believe
	*/
	.block-whyus {
		float:left;
		width:100%;
		background-color:#77C9B3;

		.whyus-item {
			float:left;
			width:100%;
			padding:40px;
			background-color:#fff;
			margin-bottom:20px;

			@include md {
				margin-top:20px;
				margin-bottom:0;
			}
		}
	}

	/*
	Quote
	*/
	.block-quote {
		float:left;
		width:100%;
		background-color:#fff;
		text-align:center;

		.quote {
			display:inline-block;
			width:600px;

			@include md {
				width:80%;
			}

			.image {
				display:inline-block;
				width:200px;
				height:200px;
				background-color:#ccc;
				background-size:cover;
				background-position:center;
				border-radius:50%;
			}

			.name {
				float:left;
				margin-top:20px;
				width:100%;
				font-family:'Roboto Slab';
				font-size:30px;
				font-weight:bold;
				color:#000;

			}

			.text {
				float:left;
				margin-top:20px;
				width:100%;
				font-style: italic;
				color:#000;
			}

			.subtext {
				float:left;
				margin-top:10px;
				width:100%;
				color:#000;
			}
		}
	}

	.block-accordeon {
		float:left;
		width:100%;

		.accordeon-item {
			float:left;
			width:100%;
			background-color:#E9E9E9;
			cursor:pointer;
			transition:0.2s all;
			
			&:hover {
				background-color:#e1e1e1;
			}

			&.active {
				background-color:#000;

				.header {
					.title {
						color:#fff;
					}

					svg {
						transform:rotate(90deg);
					}
				}
			}

			.header {
				float:left;
				width:100%;
				padding:40px;

				@include md {
					padding:20px;
				}

				.title {
					float:left;
					width:80%;
					font-family:'Roboto Slab';
					font-size:24px;
					line-height:24px;
					font-weight:bold;
					color:#000;

					@include md {
						font-size:16px;
						line-height:20px;
					}
				}

				.arrow {
					float:right;
					width:20%;

					svg {
						float:right;
						fill:#fff;
						transition:0.2s all;

						@include md {
							margin-top:10px;
						}
					}
				}
			}

			.content {
				float:left;
				width:100%;
				padding:40px;
				background-color:#fff;
			}
		}
	}

	/*
	Block: Apply
	*/
	.block-apply {
		float:left;
		width:100%;

		.contactblock { 
			float:left;
			width:100%;
			margin-top:40px;

			.column {
				float:left;
				width:30%;
				margin-left:30px;

				@include md {
					width:100%;
					margin-left:0;
					margin-bottom:20px;
					text-align:center;
				}

				&:first-child {
					margin-left:0;
				}

				a { 
					color:#000;
				}
			}
		}
	}

}