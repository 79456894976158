@import "_default.scss_include-mixins";	 

header {

	position:absolute;
	float:left;
	width:100%;
	padding-top:30px;
	padding-bottom:30px;
	background-color:rgba(255,255,255,0.8);
	z-index:10;

	@include md {
		padding-top:15px;
		padding-bottom:15px;
	}

	.left {
		float:left;

		@include md {
			margin-top:5px;
		}

		.title {
			float:left;
			margin-top:10px;
			font-family:'Roboto Slab';
			font-size:40px;
			line-height:40px;
			font-weight:bold;
			color:#000;
			text-decoration:none;

			@include md {
				margin-top:0;
				font-size:20px;
				line-height:20px;
			}

		}

		.logo {
			float:left;
			margin-left:20px;
			width:200px;
			height:62px;
			text-decoration:none;

			@include md {
				margin-left:10px;
				width:65px;
				height:20px;
			}

			svg {
				.st0{
					fill:#77C9B3;
				}
			}
		}

		nav {
			float:left;
			margin-left:80px;
			margin-top:15px;

			@include md {
				display:none;
			}

			a {
				float:left;
				font-family:'Roboto Slab';
				font-size:25px;
				font-weight:bold;
				color:#000;
				padding-bottom:5px;
				margin-left:50px;
				text-decoration:none;

				&.active {
					border-bottom:3px solid #000;
				}

				&:first-child {
					margin-left:0;
				}
			}
		}

	}

	.right {
		float:right;
		margin-top:8px;

		@include md {
			margin-top:0;
		}

		.menu-button {
			float:right;
			position:relative;
			width:43px;
			height:43px;
			background-color:#fff;
			cursor:pointer;
			box-shadow:0 0 10px rgba(0,0,0,0.2);
			z-index:100;
			border-radius:50%;

			@include md {
				margin-left:10px;
				width:30px;
				height:30px;
				box-shadow:0 0 5px rgba(0,0,0,0.2);
			}

			.line {
				position:absolute;
				height:3px;

				left:5px;
				width:20px;
				background-color:#000;

				&.line-1 {
					top:9px;
				}

				&.line-2 {
					top:14px;
				}

				&.line-3 {
					top:19px;
				}
			}
		}

		.button {
			float:right;

			@include md {
				display:none;
			}

		}

	}

}